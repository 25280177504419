import st from "./brands.module.scss";
import BrandItem from "./BrandItem";
import { motion } from "framer-motion";
import Wrapper from "../../../components/Wrapper";
import { BigTitleSection } from "../../../components/ui/BigTitleSection";
import Container from "../../../components/Container";
import { Link, Route, Routes } from "react-router-dom";

interface BrandsInterface {
  image: string;
  imageMobile?: string;
  date: BrandInterface[];
}
interface BrandInterface {
  image: string;
  title: string;
  text: string;
}

const brandsDate: BrandsInterface[] = [
  {
    image: "/assets/image/logo_brand_1.webp",
    // imageMobile: '/assets/image/logo_1_mobile.webp',
    date: [
      {
        image: "/assets/image/certificate_1.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_2.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_3.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_4.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_5.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
    ],
  },
  {
    image: "/assets/image/logo_2.webp",
    date: [
      {
        image: "/assets/image/certificate_1.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_2.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_3.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_4.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_5.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
    ],
  },
  {
    image: "/assets/image/logo_3.webp",
    date: [
      {
        image: "/assets/image/certificate_1.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_2.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_3.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_4.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_5.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
    ],
  },
  {
    image: "/assets/image/logo_4.webp",
    date: [
      {
        image: "/assets/image/certificate_1.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_2.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_3.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_4.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
      {
        image: "/assets/image/certificate_5.webp",
        title: "Lorem ipsum",
        text: "Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatu. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat.",
      },
    ],
  },
];

const Brands: React.FC = () => {
  return (
    <Wrapper className={st.brands}>
      <Container className={st.container}>
        <BigTitleSection>Продукция</BigTitleSection>
        <ul id="brands_id" className={st.brands__grid}>
          <li className={st.brand__item}>
            <Link to='products?id=1' className={st.brand__body1}>
              <div className={st.brand__block}>
                <img
                  className={st.item}
                  width={260}
                  height={57}
                  src="/assets/image/logo_brand_1.webp"
                  alt="логотип бренда"
                ></img>
              </div>
              <p className={st.info}>
                PVC без содержания кадмия, с использованием свиновых или кальций
                - цинковых стабилизаторов
              </p>
              <div className={st.text_block}>
                <p className={st.text}>
                  Количество камер:&ensp;
                  <span>4</span>
                </p>
                <p className={st.text}>
                  Системная глубина:&ensp;
                  <span>6000 мм</span>
                </p>
              </div>
            </Link>
          </li>
          <li className={st.brand__item}>
            <Link to='products?id=4' className={st.brand__body}>
              <div className={st.brand__block}>
                <img
                  className={st.item}
                  src="/assets/image/logo_2.webp"
                  alt="логотип бренда"
                ></img>
              </div>
              <div className={st.text_block}>
                <p className={st.text}>
                  Количество камер:&ensp;
                  <span>5</span>
                </p>
                <p className={st.text}>
                  Системная глубина:&ensp;
                  <span>7500 мм</span>
                </p>
              </div>
            </Link>
          </li>
          <li className={st.brand__item}>
            <Link to='products?id=2' className={st.brand__body}>
              <div className={st.brand__block}>
                <img
                  className={st.item}
                  src="/assets/image/logo_3.webp"
                  alt="логотип бренда"
                ></img>
              </div>
              <div className={st.text_block}>
                <p className={st.text}>
                  Количество камер:&ensp;
                  <span>3</span>
                </p>
                <p className={st.text}>
                  Системная глубина:&ensp;
                  <span>6000 мм</span>
                </p>
              </div>
            </Link>
          </li>
          <li className={st.brand__item}>
            <Link to='products?id=3' className={st.brand__body}>
              <div className={st.brand__block}>
                <img
                  className={st.item}
                  src="/assets/image/logo_4.webp"
                  alt="логотип бренда"
                ></img>
              </div>
              <div className={st.text_block}>
                <p className={st.text}>
                  Количество камер:&ensp;
                  <span>5</span>
                </p>
                <p className={st.text}>
                  Системная глубина:&ensp;
                  <span>6500 мм</span>
                </p>
              </div>
            </Link>
          </li>
          <li className={st.brand__item}>
            <Link to='products?id=5' className={st.brand__body}>
              <div className={st.brand__block}>
                <img
                  className={st.item}
                  src="/assets/image/Bestwin.svg"
                  alt="логотип бренда"
                ></img>
              </div>
              <div className={st.text_block}>
                <p className={st.text}>
                  Количество камер:&ensp;
                  <span>4</span>
                </p>
                <p className={st.text}>
                  Системная глубина:&ensp;
                  <span>6000 мм</span>
                </p>
              </div>
            </Link>
          </li>
        </ul>
        <ul className={st.accessories}>
          <li className={st.accessories_item}>
            <p className={st.accessories_text}>ПВХ продукция (трубы, боксы)</p>
          </li>
          <li className={st.accessories_item}>
            <p className={st.accessories_text}>Фурнтиры и комплектующие</p>
          </li>
          <li className={st.accessories_item}>
            <p className={st.accessories_text}>Подоконники и дверные панели</p>
          </li>
          <li className={st.accessories_item}>
            <p className={st.accessories_text}>ПВХ обшивки</p>
          </li>
          <li className={st.accessories_item}>
            <p className={st.accessories_text}>Кабель-каналы</p>
          </li>
        </ul>
      </Container>
    </Wrapper>
  );
};
export default Brands;
