import { IBanner } from "../../models/IBanner"
import Banner from "../../components/Banner"
import Timeline from "../../components/Timeline"
import Accordion from "../../components/Accordion"
import { IAccordion } from "../../models/IAccordion"
import { ITimeline } from "../../models/ITimeline"
import Statistics from "../../components/Statistics"


const bannerDate: IBanner = {
	title: 'ДВЕРЬ',
	text: 'Благодаря солидной фурнитуре и надёжной конструкции, входные двери ПВХ могут использоваться в местах с повышенной проходимостью. Такие двери могут обеспечить проходимость как для коммерческих помещений, так и для частного дома',
	image: '/assets/image/doorPage_banner.webp',
	link: 'Бесплатный замер'
}
const dateAccordion: IAccordion[] = [
	{
		name: 'Пластиковые входные двери ROLLER',
		text: 'Изготовление такой продукции имеет свои особенности. Чтобы жёсткость полотна сохранялась во время эксплуатации, устанавливается импост – горизонтальная перемычка, которая разделяет дверь на две части. Эта деталь обычно располагается на уровне подоконника. Если дверной проем имеет большие размеры, балконная дверь может быть двустворчатой. Предусмотрены разные режимы работы фурнитуры:',
		items: [
			{
				// itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'поворотный – обычное открывание дверного блока;',
					}, {
						// itemName: 'asd',
						itemText: 'откидывание – при необходимости интенсивного проветривания верхняя часть двери откидывается на 15-20 см',
					}, {
						// itemName: 'asd',
						itemText: 'зимнее проветривание – в холодное время года для проветривания можно оставить щель шириной 4-10 мм.',
					}
				]
			},
		]
	}, {
		name: 'Как формируется цена на двери?',
		// text: 'Стоимость пластиковых входных дверей из профилей зависит от:',
		items: [
			{
				itemsName: 'Стоимость пластиковых входных дверей из профилей зависит от:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'выбора системы профиля – трех, четырех, пятикамерная и более;',
					}, {
						// itemName: 'asd',
						itemText: 'фурнитуры;',
					}, {
						// itemName: 'asd',
						itemText: 'замка;',
					}, {
						// itemName: 'asd',
						itemText: 'заполнения, декора;',
					}, {
						// itemName: 'asd',
						itemText: 'уплотнителя;',
					}, {
						// itemName: 'asd',
						itemText: 'типа открывания;',
					}, {
						// itemName: 'asd',
						itemText: 'материала порога (пластик или алюминий).',
					}
				]
			}
		]
	}, {
		name: 'Особенности пластиковых входных дверей из профилей​',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Фурнитура для пластиковых входных дверей из профилей​',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}
]
const dateTimelineItems: ITimeline[] = [
	{
		key: 1,
		title: 'Пятикамерная система',
		// subtitle: 'профильная система',
		text: 'Классическая стабильная пятикамерная система оконных профилей с превосходными изолирующими характеристиками',
	}, {
		key: 2,
		title: 'Цветные',
		subtitle: 'ламинированные профили',
		text: 'Цветные ламинированные профили, которые способны подчеркнуть и дополнить самые смелые дизайнерские задумки. Уникальная технология нанесения цветных пленок на профили позволяет делать это как с одной, так и с обеих сторон. Это дает Вам возможность комбинировать цвета по своему вкусу',
	}, {
		key: 3,
		title: 'Раскладка в стеклопакет',
		text: 'Раскладка в стеклопакет — это декоративные переплеты как элегантного белого цвета, «под ценные породы дерева» и, вообще, яркие цвета',
	}, {
		key: 4,
		title: 'Многозапорные',
		subtitle: 'замковые системы',
		text: 'Многозапорные замковые системы при закрывании двери позволяют добиться предельно высокой плотности прижима и надежности',
	}, {
		key: 5,
		title: 'Прочная конструкция',
		text: 'Конструкция входных дверей допускает установку ударостойких и сверхпрочных стекол, для повышения уровня защиты',
	}, {
		key: 6,
		title: 'Алюминевый порог',
		text: 'Наличие порога из алюминия высотой 20 мм позволяет избежать дискомфорта при перешагивании, сохраняя в тоже время герметичность при закрывании',
	}
]


const DoorPage = () => {
	return (<>
		<Banner banner={bannerDate} />
		<Timeline
			imgDisplay={false}
			items={dateTimelineItems}
			subtitleMain='Основные преимущества'
			titleMain='Что делает пластиковые балконные	двери ROLLER самыми эффективными?'
		/>
		<Statistics />
		<Accordion title="ЭЛЕМЕНТЫ ФУРНИТУРЫ" props={dateAccordion} />
	</>)
}
export default DoorPage