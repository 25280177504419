import classes from "./windowTypes.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import { useState } from "react";

function WindowTypes() {
  const swiper = [
    {
      id: "1",
      imgSmall: "/assets/image/windowSwiper/small/1.svg",
      imgBig: "/assets/image/windowSwiper/big/1.svg",
    },
    {
      id: "2",
      imgSmall: "/assets/image/windowSwiper/small/2.svg",
      imgBig: "/assets/image/windowSwiper/big/2.svg",
    },
    {
      id: "3",
      imgSmall: "/assets/image/windowSwiper/small/3.svg",
      imgBig: "/assets/image/windowSwiper/big/3.svg",
    },
    {
      id: "4",
      imgSmall: "/assets/image/windowSwiper/small/4.svg",
      imgBig: "/assets/image/windowSwiper/big/4.svg",
    },
    {
      id: "5",
      imgSmall: "/assets/image/windowSwiper/small/5.svg",
      imgBig: "/assets/image/windowSwiper/big/5.svg",
    },
    {
      id: "6",
      imgSmall: "/assets/image/windowSwiper/small/6.svg",
      imgBig: "/assets/image/windowSwiper/big/6.svg",
    },
    {
      id: "7",
      imgSmall: "/assets/image/windowSwiper/small/7.svg",
      imgBig: "/assets/image/windowSwiper/big/7.svg",
    },
    {
      id: "8",
      imgSmall: "/assets/image/windowSwiper/small/8.svg",
      imgBig: "/assets/image/windowSwiper/big/8.svg",
    },
    {
      id: "9",
      imgSmall: "/assets/image/windowSwiper/small/9.svg",
      imgBig: "/assets/image/windowSwiper/big/9.svg",
    },
    {
      id: "10",
      imgSmall: "/assets/image/windowSwiper/small/10.svg",
      imgBig: "/assets/image/windowSwiper/big/10.svg",
    },
    {
      id: "11",
      imgSmall: "/assets/image/windowSwiper/small/11.svg",
      imgBig: "/assets/image/windowSwiper/big/11.svg",
    },
    {
      id: "12",
      imgSmall: "/assets/image/windowSwiper/small/12.svg",
      imgBig: "/assets/image/windowSwiper/big/12.svg",
    },
    {
      id: "13",
      imgSmall: "/assets/image/windowSwiper/small/13.svg",
      imgBig: "/assets/image/windowSwiper/big/13.svg",
    },
    {
      id: "14",
      imgSmall: "/assets/image/windowSwiper/small/14.svg",
      imgBig: "/assets/image/windowSwiper/big/14.svg",
    },
    {
      id: "15",
      imgSmall: "/assets/image/windowSwiper/small/15.svg",
      imgBig: "/assets/image/windowSwiper/big/15.svg",
    },
    {
      id: "16",
      imgSmall: "/assets/image/windowSwiper/small/16.svg",
      imgBig: "/assets/image/windowSwiper/big/16.svg",
    },
    {
      id: "17",
      imgSmall: "/assets/image/windowSwiper/small/17.svg",
      imgBig: "/assets/image/windowSwiper/big/17.svg",
    },
    {
      id: "18",
      imgSmall: "/assets/image/windowSwiper/small/18.svg",
      imgBig: "/assets/image/windowSwiper/big/18.svg",
    },
    {
      id: "19",
      imgSmall: "/assets/image/windowSwiper/small/19.svg",
      imgBig: "/assets/image/windowSwiper/big/19.svg",
    },
    {
      id: "20",
      imgSmall: "/assets/image/windowSwiper/small/20.svg",
      imgBig: "/assets/image/windowSwiper/big/20.svg",
    },
    {
      id: "21",
      imgSmall: "/assets/image/windowSwiper/small/21.svg",
      imgBig: "/assets/image/windowSwiper/big/21.svg",
    },
    {
      id: "22",
      imgSmall: "/assets/image/windowSwiper/small/22.svg",
      imgBig: "/assets/image/windowSwiper/big/22.svg",
    },
    {
      id: "23",
      imgSmall: "/assets/image/windowSwiper/small/23.svg",
      imgBig: "/assets/image/windowSwiper/big/23.svg",
    },
    {
      id: "24",
      imgSmall: "/assets/image/windowSwiper/small/24.svg",
      imgBig: "/assets/image/windowSwiper/big/24.svg",
    },
    {
      id: "25",
      imgSmall: "/assets/image/windowSwiper/small/25.svg",
      imgBig: "/assets/image/windowSwiper/big/25.svg",
    },
    {
      id: "26",
      imgSmall: "/assets/image/windowSwiper/small/26.svg",
      imgBig: "/assets/image/windowSwiper/big/26.svg",
    },
    {
      id: "27",
      imgSmall: "/assets/image/windowSwiper/small/27.svg",
      imgBig: "/assets/image/windowSwiper/big/27.svg",
    },
    {
      id: "28",
      imgSmall: "/assets/image/windowSwiper/small/28.svg",
      imgBig: "/assets/image/windowSwiper/big/28.svg",
    },
    {
      id: "29",
      imgSmall: "/assets/image/windowSwiper/small/29.svg",
      imgBig: "/assets/image/windowSwiper/big/29.svg",
    },
    {
      id: "30",
      imgSmall: "/assets/image/windowSwiper/small/30.svg",
      imgBig: "/assets/image/windowSwiper/big/30.svg",
    },
    {
      id: "31",
      imgSmall: "/assets/image/windowSwiper/small/31.svg",
      imgBig: "/assets/image/windowSwiper/big/31.svg",
    },
    {
      id: "32",
      imgSmall: "/assets/image/windowSwiper/small/32.svg",
      imgBig: "/assets/image/windowSwiper/big/32.svg",
    },
    {
      id: "33",
      imgSmall: "/assets/image/windowSwiper/small/33.svg",
      imgBig: "/assets/image/windowSwiper/big/33.svg",
    },
    {
      id: "34",
      imgSmall: "/assets/image/windowSwiper/small/34.svg",
      imgBig: "/assets/image/windowSwiper/big/34.svg",
    },
    {
      id: "35",
      imgSmall: "/assets/image/windowSwiper/small/35.svg",
      imgBig: "/assets/image/windowSwiper/big/35.svg",
    },
    {
      id: "36",
      imgSmall: "/assets/image/windowSwiper/small/36.svg",
      imgBig: "/assets/image/windowSwiper/big/36.svg",
    },
    {
      id: "37",
      imgSmall: "/assets/image/windowSwiper/small/37.svg",
      imgBig: "/assets/image/windowSwiper/big/37.svg",
    },
    {
      id: "38",
      imgSmall: "/assets/image/windowSwiper/small/38.svg",
      imgBig: "/assets/image/windowSwiper/big/38.svg",
    },
    {
      id: "39",
      imgSmall: "/assets/image/windowSwiper/small/39.svg",
      imgBig: "/assets/image/windowSwiper/big/39.svg",
    },
    {
      id: "40",
      imgSmall: "/assets/image/windowSwiper/small/40.svg",
      imgBig: "/assets/image/windowSwiper/big/40.svg",
    },
    {
      id: "41",
      imgSmall: "/assets/image/windowSwiper/small/41.svg",
      imgBig: "/assets/image/windowSwiper/big/41.svg",
    },
    {
      id: "42",
      imgSmall: "/assets/image/windowSwiper/small/42.svg",
      imgBig: "/assets/image/windowSwiper/big/42.svg",
    },
    {
      id: "43",
      imgSmall: "/assets/image/windowSwiper/small/43.svg",
      imgBig: "/assets/image/windowSwiper/big/43.svg",
    },
    {
      id: "44",
      imgSmall: "/assets/image/windowSwiper/small/44.svg",
      imgBig: "/assets/image/windowSwiper/big/44.svg",
    },
    {
      id: "45",
      imgSmall: "/assets/image/windowSwiper/small/45.svg",
      imgBig: "/assets/image/windowSwiper/big/45.svg",
    },
    {
      id: "46",
      imgSmall: "/assets/image/windowSwiper/small/46.svg",
      imgBig: "/assets/image/windowSwiper/big/46.svg",
    },
    {
      id: "47",
      imgSmall: "/assets/image/windowSwiper/small/47.svg",
      imgBig: "/assets/image/windowSwiper/big/47.svg",
    },
  ];
  const [activeImage, setActiveImage] = useState(swiper[0].imgBig);
  const [activeSlide, setActiveSlide] = useState(swiper[0]);
  const handleClickImage = (slide: any) => () => {
    setActiveImage(slide.imgBig);
    setActiveSlide(slide);
  };
  return (
    <div className={classes.windowTypes}>
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <div>
            <h1 className={classes.h1}>типы окон</h1>
            <div className={classes.swiper}>
              <Swiper
                slidesPerView={5}
                spaceBetween={10}
                navigation={true}
                modules={[Navigation]}
                className={classes.mySwiper}
                breakpoints={{
                  592: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                  },
                }}
              >
                {swiper.map((slide) => (
                  <SwiperSlide
                    onClick={handleClickImage(slide)}
                    className={classes.slide}
                    key={slide.id}
                  >
                    <img
                      className={classes.imgSmall}
                      src={slide.imgSmall}
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div>
            <img className={classes.window} src={activeImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WindowTypes;
