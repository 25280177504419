import { IBanner } from "../../models/IBanner"
import Banner from "../../components/Banner"
import Timeline from "../../components/Timeline"
import Accordion from "../../components/Accordion"
import { IAccordion } from "../../models/IAccordion"
import { ITimeline } from "../../models/ITimeline"
import Statistics from "../../components/Statistics"
import BlockInfo from "./BlockInfo"


const bannerDate: IBanner = {
	title: 'остекление балконов и лоджий',
	text: 'Чем отличается распашное остекление балконов и лоджий?	В каких ситуациях распашное остекление имеет преимущества, а в каких его использование не оправдано?',
	image: '/assets/image/balcinyClazing.webp',
	link: 'Бесплатный замер'
}
const dateAccordion: IAccordion[] = [
	{
		name: 'Распашное остекление',
		text: 'Изготовление такой продукции имеет свои особенности. Чтобы жёсткость полотна сохранялась во время эксплуатации, устанавливается импост – горизонтальная перемычка, которая разделяет дверь на две части. Эта деталь обычно располагается на уровне подоконника. Если дверной проем имеет большие размеры, балконная дверь может быть двустворчатой. Предусмотрены разные режимы работы фурнитуры:',
		items: [
			{
				// itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'поворотный – обычное открывание дверного блока;',
					}, {
						// itemName: 'asd',
						itemText: 'откидывание – при необходимости интенсивного проветривания верхняя часть двери откидывается на 15-20 см',
					}, {
						// itemName: 'asd',
						itemText: 'зимнее проветривание – в холодное время года для проветривания можно оставить щель шириной 4-10 мм.',
					}
				]
			},
		]
	}, {
		name: 'Преимущества распашного остекления',
		text: 'Этот тип остекления широко распространён благодаря его преимуществам:',
		items: [
			{
				itemsName: 'Стоимость пластиковых входных дверей из профилей зависит от:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Мыть открывающиеся внутрь окна очень просто — не нужно высовываться из окна, пытаясь оттереть грязь снаружи.;',
					}, {
						// itemName: 'asd',
						itemText: 'Лоджию можно сделать теплой и тихой — на распашные створки устанавливаются стеклопакеты, обеспечивающие тепло- и звукоизоляцию.;',
					}
				]
			}
		]
	}, {
		name: 'Особенности пластиковых входных дверей из профилей​',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Фурнитура для пластиковых входных дверей из профилей​',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}
]
const dateTimelineItems: ITimeline[] = [
	{
		key: 1,
		title: 'Многомерная система',
		// subtitle: 'профильная система',
		text: 'Раздвижная многомерная система остекления балконов и лоджий для их надежной защиты',
	}, {
		key: 2,
		title: 'Плотное уплотнение',
		// subtitle: 'профильная система',
		text: 'Плотное щеточное уплотнение, исключающее продувание конструкции',
	}, {
		key: 3,
		title: 'Высокое качество',
		text: 'Специальная высококачественная фурнитура Roller',
	}, {
		key: 4,
		title: 'Алюминевый рельс',
		text: 'Применение алюминиевого рельса позволяет изготавливать элементы нестандартных размеров. На конструкции может устанавливаться москитная сетка',
	}
]


const BalconyGlazing = () => {
	return (<>
		<Banner banner={bannerDate} />
		<Timeline
			imgDisplay={false}
			items={dateTimelineItems}
			subtitleMain='Основные преимущества'
			titleMain='ЧТО ДЕЛАЕТ ОКНО ROLLER САМЫМ ЭФФЕКТИВНЫМ?'
		/>
		<Statistics />
		<Accordion title="ДОПОЛНИТЕЛЬНЫЕ ПРЕИМУЩЕСТВА" props={dateAccordion} />
		<BlockInfo />
	</>)
}
export default BalconyGlazing