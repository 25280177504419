import classes from "./productsSwiper.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { Navigation, Controller } from "swiper/modules";
import { useState } from "react";
import { products } from "../../pages/ProductsPage";
import { useNavigate } from "react-router-dom";
import clsx from "clsx";

interface Props {
  selectProduct: (id: number) => void;
  activeProductId: number;
}

function ProductsSwiper({ selectProduct, activeProductId }: Props) {
  const navigate = useNavigate();
  return (
    <div className={classes.productsSwiper}>
      <div className={classes.container}>
        <Swiper
          // touchRatio={0}
          initialSlide={2}
          slidesPerView={3}
          // slidesPerGroup={1}
          spaceBetween={5}
          centeredSlides={true}
          navigation={true}
          slideToClickedSlide={true}
          autoHeight={true}
          breakpoints={{
            592: {
              slidesPerView: 2,
              spaceBetween: 24,
            },
            1200: {
              slidesPerView: 5,
              spaceBetween: 60,
              slideToClickedSlide: true,
              centeredSlides: true,
              initialSlide: 2,
            },
          }}
          modules={[Navigation, Controller]}
          className={classes.mySwiper}
        >
          {products.map((product) => (
            <SwiperSlide
              onClick={() => {
                // selectProduct(product.id);
                navigate(`/products?id=${product.id}`);
              }}
              className={
                clsx(
                  activeProductId == product.id
                  ? classes.active
                  : classes.notActive,
                  classes.slider_item
                )
              }
            >
              <img className={classes.img} src={product.logo} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
}

export default ProductsSwiper;
