import classes from "../windowTypes/windowTypes.module.scss";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import { useState } from "react";

function DoorTypes() {
  const swiper = [
    {
      id: "1",
      imgSmall: "/assets/image/doorSwiper/small/1.svg",
      imgBig: "/assets/image/doorSwiper/big/1.svg",
    },
    {
      id: "2",
      imgSmall: "/assets/image/doorSwiper/small/2.svg",
      imgBig: "/assets/image/doorSwiper/big/2.svg",
    },
    {
      id: "3",
      imgSmall: "/assets/image/doorSwiper/small/3.svg",
      imgBig: "/assets/image/doorSwiper/big/3.svg",
    },
    {
      id: "4",
      imgSmall: "/assets/image/doorSwiper/small/4.svg",
      imgBig: "/assets/image/doorSwiper/big/4.svg",
    },
    {
      id: "5",
      imgSmall: "/assets/image/doorSwiper/small/5.svg",
      imgBig: "/assets/image/doorSwiper/big/5.svg",
    },
    {
      id: "6",
      imgSmall: "/assets/image/doorSwiper/small/6.svg",
      imgBig: "/assets/image/doorSwiper/big/6.svg",
    },
    {
      id: "7",
      imgSmall: "/assets/image/doorSwiper/small/7.svg",
      imgBig: "/assets/image/doorSwiper/big/7.svg",
    },
    {
      id: "8",
      imgSmall: "/assets/image/doorSwiper/small/8.svg",
      imgBig: "/assets/image/doorSwiper/big/8.svg",
    },
  ];
  const [activeImage, setActiveImage] = useState(swiper[0].imgBig);
  const [activeSlide, setActiveSlide] = useState(swiper[0]);
  const handleClickImage = (slide: any) => () => {
    setActiveImage(slide.imgBig);
    setActiveSlide(slide);
  };
  return (
    <div className={classes.windowTypes}>
      <div className={classes.wrapper}>
        <div className={classes.block}>
          <div>
            <h1 className={classes.h1}>типы дверей</h1>
            <div className={classes.swiper}>
              <Swiper
                slidesPerView={5}
                spaceBetween={10}
                navigation={true}
                modules={[Navigation]}
                className={classes.mySwiper}
                breakpoints={{
                  592: {
                    slidesPerView: 2,
                    spaceBetween: 24,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 60,
                  },
                }}
              >
                {swiper.map((slide) => (
                  <SwiperSlide
                    onClick={handleClickImage(slide)}
                    className={classes.slide}
                    key={slide.id}
                  >
                    <img
                      className={classes.imgSmall}
                      src={slide.imgSmall}
                      alt=""
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </div>
          <div>
            <img className={classes.window} src={activeImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoorTypes;
