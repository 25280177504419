import Solution from "../solution/Solution";
import WindowRoller from "../windowRoller/WindowRoller";
import Colors from "../colors/Colors";
import Advantages from "../advantages/Advantages";
import WindowTypes from "../windowTypes/WindowTypes";
import DoorTypes from "../doorTypes/DoorTypes";

function WrapperRoller() {
  return (
    <div>
      <Solution />
      <WindowRoller /> 
      <WindowTypes />
      <DoorTypes/>
      <Colors />
      <Advantages />
    </div>
  );
}

export default WrapperRoller;
