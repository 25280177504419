import { IBanner } from "../../models/IBanner"
import Banner from "../../components/Banner"
import Accordion from "../../components/Accordion"
import { IAccordion } from "../../models/IAccordion"
import BlockInfoFittings from "../../components/BlockInfoFittings"
import { IBlockInfoFittings } from "../../models/IBlockInfoFittings"
import BlockInfo from "./BlockInfo"
import { IBlockInfo } from "../../models/IBlockInfo"
import Description from "./Description"

const bannerDate: IBanner = {
	title: 'ПЛАСТИКОВЫЕ ПОДОКОННИКИ',
	text: 'Подоконник ПВХ ROLLER отвечает современным запросам дизайна для контрастных акцентов в интерьере в сочетании с такими элементами как двери, окна и мебель черного дерева.',
	image: '/assets/image/PlasticWindow_banner.webp',
	link: 'Бесплатный замер'
}

const dateAccordion: IAccordion[] = [
	{
		name: 'Двери для балкона из ПВХ',
		text: 'Изготовление такой продукции имеет свои особенности. Чтобы жёсткость полотна сохранялась во время эксплуатации, устанавливается импост – горизонтальная перемычка, которая разделяет дверь на две части. Эта деталь обычно располагается на уровне подоконника. Если дверной проем имеет большие размеры, балконная дверь может быть двустворчатой. Предусмотрены разные режимы работы фурнитуры:',
		items: [
			{
				// itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'поворотный – обычное открывание дверного блока;',
					}, {
						// itemName: 'asd',
						itemText: 'откидывание – при необходимости интенсивного проветривания верхняя часть двери откидывается на 15-20 см',
					}, {
						// itemName: 'asd',
						itemText: 'зимнее проветривание – в холодное время года для проветривания можно оставить щель шириной 4-10 мм.',
					}
				]
			},
		]
	}, {
		name: 'Особенности балконных ПВХ-дверей',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Заказать пластиковую балконную дверь у партнеров ROLLER',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}
]

const dateBlockInfo: IBlockInfoFittings = {
	image: '/assets/image/img_3211.webp',
	title: 'ПЛАСТИКОВЫЕ ПОДОКОННИКИ',
	text: 'Подоконники ROLLER – это эксклюзивные решение для вашего дома! Подоконники ROLLER серии Premium не оставят Вас равнодушными! Изделия изготавливаются из экологически чистого европейского пластика и обладают идеально гладкой акриловой поверхностью. Подоконник ПВХ ROLLER отвечает современным запросам дизайна для контрастных акцентов в интерьере в сочетании с такими элементами как двери, окна и мебель черного дерева.',
	linkButton: 'string'
}
const dateBlockInfo1: IBlockInfo = {
	subtitle: 'Варианты ламинации',
	title: 'Мы предлагаем различные варианты цветной ламинации ПВХ',
	text: 'Условно их можно разделить на стандартные (популярные) и редко используемые. Стандартные цвета стоят значительно дешевле не стандартных.Стандартные цвета есть в наличии и могут быть изготовленны в сроки обычных белых окон. Всегда в наличии ламинация «под дерево» (ЗОЛОТОЙ ДУБ, ОРЕГОН, ТЕМНЫЙ ДУБ, БОЛОТНЫЙ ДУБ, ПОЛОСАТЫЙ ДУГЛАС, ГОРНАЯ СОСНА, МАХАГОН, СВЕТЛЫЙ ДУБ, ОРЕХ) Редко используемые цвета — ЭТО полностью заказные позиции (большой срок изготовления), ламинируются под заказ.',
	image: '/assets/image/image_312.webp',
}

const PlasticWindow = () => {
	return (<>
		<Banner banner={bannerDate} />
		<BlockInfoFittings props={dateBlockInfo} buttonShow={true} />
		<BlockInfo props={dateBlockInfo1} variant='first' />
		<Description />
		<Accordion title="ЭЛЕМЕНТЫ ФУРНИТУРЫ" props={dateAccordion} />
	</>)
}
export default PlasticWindow