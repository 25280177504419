import { useState } from "react";
import st from "./PartnerItem.module.scss";
import ComponentModal from "../modal";
import clsx from "clsx";

interface Props {
  classname?: string;
  partnerItem: BrandsInterface;
}
interface BrandsInterface {
  image: string;
  title: string;
  sistemy: string;
  country: string;
  standart: string;
  imageMobile?: string;
  date: BrandInterface[];
}
interface BrandInterface {
  image: string;
  title: string;
  text: string;
  certification: string;
  number: string;
}

const PartnerItem = ({ partnerItem, classname }: Props) => {
  const [modalActive, setModalActive] = useState(false);
  return (
    <>
      <div
        // className={`${st.partner__body} `}
        className={clsx(
          st.partner__body,
          classname
        )}
        onClick={() => setModalActive(true)}
      >
        <div>
          <p className={st.title}>{partnerItem.title}</p>
          <div className={st.block_text}>
            <p className={st.text}>{partnerItem.sistemy}</p>
            <p className={st.text}>
              Страна:<span>{partnerItem.country}</span>
            </p>
            <p className={st.text}>
              Стандарт:
              <span>{partnerItem.standart}</span>
            </p>
          </div>
        </div>

        <div className={st.bl_img}>
          <img className={st.partners__img} src={partnerItem.image} />
        </div>
      </div>
      <ComponentModal
        dataModal={partnerItem.date}
        active={modalActive}
        setActive={setModalActive}
      />
    </>
  );
};
export default PartnerItem;
