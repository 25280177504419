import ColorsUnopen from "../colorsUnopen/ColorsUnopen";
import Advantages from "../advantages/Advantages";
import WindowTypes from "../windowTypes/WindowTypes";
import SolutionEcoline from "../solutionEcoline/SolutionEcoline";
import WindowEcoline from "../windowEcoline/WindowEcoline";
import DoorTypes from "../doorTypes/DoorTypes";
import { Link } from "react-router-dom";

function WrapperEcoline() {
  return (
    <div>
      <SolutionEcoline />
      <WindowEcoline />
      <WindowTypes />
      <DoorTypes/>
      <ColorsUnopen />
      <Advantages />
    </div>
  );
}

export default WrapperEcoline;
