import classes from "./windowRoller.module.scss";

function WindowRoller() {
  return (
    <div className={classes.WindowRoller}>
      <div className={classes.wrapper}>
        <div className={classes.near}>
          <h1 className={classes.h1}>ОКНА</h1>
          <h2 className={classes.h2Mobi}>ROLLER 6000</h2>
        </div>
        <div className={classes.block}>
          <div className="">
            <h2 className={classes.h2}>ROLLER 6000</h2>
            <p className={classes.paragraph}>
              Все профили ROLLER производятся в соответствии с требованиями
              высшего класса качества немецкого стандарта DIN EN 12608 — класса
              А. Только из таких профилей можно сделать окна и двери, которые
              будут надёжно работать многие десятилетия, радуя Вас своим
              великолепным внешним видом и удобством использования. 100%
              качества профиля — чтобы Ваши окна дарили Вам радость. <br /> Мы
              обращаем внимание на каждую деталь и сочетание окон, как в
              интерьере, так и в экстерьере
            </p>
          </div>
          <div>
            <img className={classes.img} src="/assets/image/window1.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WindowRoller;
