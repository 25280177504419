import classes from "../windowRoller/windowRoller.module.scss";

function WindowUnopen() {
  return (
    <div className={classes.WindowRoller}>
      <div className={classes.wrapper}>
        <div className={classes.near}>
          <h1 className={classes.h1}>ОКНА</h1>
          <h2 className={classes.h2Mobi}>UNOPEN 6000</h2>
        </div>
        <div className={classes.block}>
          <div className="">
            <h2 className={classes.h2}>UNOPEN 6500</h2>
            <p className={classes.paragraph}>
              Профили Unopen 6500 - это PVC без содержания кадмия, с
              использованием свинцовых или кальций - цинковых стабилизаторов.
              Это и есть одно из требований мировых стандартов, которые мы
              тщательно стремимся поддерживать. Выбирая Unopen - вы выбираете
              долговечность и мировые стандарты пластиковых окон! 100% качества
              профиля — чтобы Ваши окна дарили Вам радость.
              <br />
              Мы обращаем внимание на каждую деталь и сочетание окон, как в
              интерьере, так и в экстерьере
            </p>
          </div>
          <div>
            <img
              className={classes.img}
              src="/assets/image/windowUnopen.jpg"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WindowUnopen;
