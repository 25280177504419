import { Swiper, SwiperSlide } from "swiper/react";
import classes from "./colors.module.scss";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";

// import required modules
import { Navigation } from "swiper/modules";
import { useState } from "react";

function Colors() {
  const swiper = [
    {
      id: "1",
      imgSmall: "/assets/image/desert-oak.png",
      text: "пустынный дуб",
      imgBig: "/assets/image/desert.png",
    },
    {
      id: "2",
      imgSmall: "/assets/image/golden-oak.png",
      text: "золотой дуб",
      imgBig: "/assets/image/golden.png",
    },
    {
      id: "3",
      imgSmall: "/assets/image/walnut.png",
      text: "грецкий орех",
      imgBig: "/assets/image/nut.png",
    },
    {
      id: "4",
      imgSmall: "/assets/image/anthracite.png",
      text: "Антрацит",
      imgBig: "/assets/image/AnthraciteTeks.png",
    },
    {
      id: "5",
      imgSmall: "/assets/image/royal.png",
      text: "Королевский",
      imgBig: "/assets/image/RoyalTeks.png",
    },
    {
      id: "6",
      imgSmall: "/assets/image/dark-oak.png",
      text: "темный дуб",
      imgBig: "/assets/image/dark.png",
    },
  ];
  const [activeImage, setActiveImage] = useState(swiper[0].imgSmall);
  const [activeImage2, setActiveImage2] = useState(swiper[0].imgBig);
  const [activeSlide, setActiveSlide] = useState(swiper[0]);
  const handleClickImage = (slide: any) => () => {
    setActiveImage(slide.imgSmall);
    setActiveImage2(slide.imgBig);
    setActiveSlide(slide);
  };
  return (
    <div className={classes.colors}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>РАСЦВЕТКИ</h1>
        <div className={classes.grid}>
          {swiper.map((slide) => (
            <div className={classes.block}>
              <div className={classes.group}>
                <img src={slide.imgSmall} alt="" />
                <p className={classes.paragraph}>{slide.text}</p>
              </div>
              <div>
                <img src={slide.imgBig} alt="" />
              </div>
            </div>
          ))}
        </div>
        <div className={classes.mobi}>
          <div className={classes.imgGroup}>
            <img className={classes.window} src={activeImage} alt="" />
            <img className={classes.window2} src={activeImage2} alt="" />
          </div>
          <div className={classes.swiper}>
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation={true}
              modules={[Navigation]}
              className={classes.mySwiper}
              breakpoints={{
                592: {
                  slidesPerView: 2,
                  spaceBetween: 24,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 60,
                },
              }}
            >
              {swiper.map((slide) => (
                <SwiperSlide
                  onClick={handleClickImage(slide)}
                  className={classes.slide}
                  key={slide.id}
                >
                  <img
                    className={classes.imgSmall}
                    src={slide.imgSmall}
                    alt=""
                  />
                  <img className={classes.imgSmall2} src={slide.imgBig} alt="" />
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Colors;
