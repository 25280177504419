import classes from "../windowRoller/windowRoller.module.scss";

function WindowStella() {
  return (
    <div className={classes.WindowRoller}>
      <div className={classes.wrapper}>
      <div className={classes.near}>
          <h1 className={classes.h1}>ОКНА</h1>
          <h2 className={classes.h2Mobi}>STELLA 7500</h2>
        </div>
        <div className={classes.block}>
          <div className="">
            <h2 className={classes.h2}>STELLA 7500</h2>
            <p className={classes.paragraph}>
              Профили Stella 7500 - пятикамерные PVC без содержания кадмия, с
              использованием свинцовых или кальций - цинковых стабилизаторов.
              Имеют трех контурную систему уплотнений, а монтажная ширина
              составляет 75 мм. Это премиальный уровень пластиковых окон,
              который соответствует мировому стандарту. 100% качества профиля —
              чтобы Ваши окна дарили Вам радость.
              <br />
              Мы обращаем внимание на каждую деталь и сочетание окон, как в
              интерьере, так и в экстерьере
            </p>
          </div>
          <div>
            <img className={classes.img} src="/assets/image/windowStella.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WindowStella;
