import st from './news.module.scss'
import { motion } from 'framer-motion'
import { Link } from 'react-router-dom'
import NewsItem from './NewsItem'
import Wrapper from '../../../components/Wrapper'
import Container from '../../../components/Container'
import { BigTitleSection } from '../../../components/ui/BigTitleSection'
import { useEffect, useState } from 'react'
import { INewsItems } from '../../../models/INewsItems'

const News = () => {
  const [newDateItems, setNewDateItems] = useState<INewsItems[] | null>(null)

  useEffect(() => {
    fetch('https://api.roller.tj/news/')
      .then((response) => response.json())
      .then((data) => {
        const sortedNews = data.data.ns.sort((a: INewsItems, b: INewsItems) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
        setNewDateItems(sortedNews)
      })
      .catch((error) => console.error('Error fetching news:', error))
  }, [])

  if (!newDateItems) {
    return <>loading...</>
  }
  
  // useEffect(() => {
  //   fetch('https://api.roller.tj/news/')
  //     .then((response) => response.json())
  //     .then((newDateItems) => setNewDateItems(newDateItems.data.ns))
  //     .catch((error) => console.error('Error fetching news:', error))
  // }, [])


  const imgVariants = {
    hidden: {
      opacity: 0,
      x: 20,
      scale: 0.5,
    },
    animate: (i: number) => ({
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        delay: (i + 1) * 0.1,
      },
    }),
  }

  return (
    <Wrapper className={st.news}>
      <Container className={st.container}>
        <div className={st.news__header}>
          <BigTitleSection>новости</BigTitleSection>
          <div className={st.news__bl_header_link}>
            <Link to="/news" className={st.news__header_link}>
              Все новости
            </Link>
          </div>
        </div>
        <ul className={st.news__items}>
          {newDateItems.map((el, index) => (
            <motion.li
              initial="hidden"
              whileInView="animate"
              custom={index}
              key={index}
              viewport={{
                once: true,
              }}
              variants={imgVariants}
            >
              <NewsItem newsItem={el} />
            </motion.li>
          ))}
        </ul>
      </Container>
    </Wrapper>
  )
}

export default News
