interface HeaderDateInterface {
  id: number;
  title: string;
  link: string;
}

export const headerDate: HeaderDateInterface[] = [
  { id: 1, title: "Главная", link: "/" },
  { id: 2, title: "О компании", link: "/aboutCompany" },
  { id: 3, title: "Галерея", link: "/photo_gallery" },
  { id: 4, title: "Продукция", link: "/products" },
  { id: 5, title: "Услуги", link: "/our_services" },
  { id: 6, title: "Новости", link: "/news" },
  { id: 7, title: "Контакты", link: "/contacts" },
];
