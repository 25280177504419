                                                              import classes from "./windowEcoline.module.scss";

function WindowEcoline() {
  return (
    <div className={classes.WindowRoller}>
      <div className={classes.wrapper}>
      <div className={classes.near}>
          <h1 className={classes.h1}>ОКНА</h1>
          <h2 className={classes.h2Mobi}>ECOLINE 6000</h2>
        </div>
        <div className={classes.block}>
          <div className="">
            <h2 className={classes.h2}>ECOLINE 6000</h2>
            <p className={classes.paragraph}>
              Ecoline 6000 - это трехкамерные профили белого цвета, которые
              совмещают в себе качество 60 мм профилей и мировые стандарты. Наша
              цель заключается в том, чтобы предоставить выбор для всех, кто
              желает установить качественные окна и двери из пластика в своем
              доме! 100% качества профиля — чтобы Ваши окна дарили Вам радость.
              <br />
              Мы обращаем внимание на каждую деталь и сочетание окон, как в
              интерьере, так и в экстерьере
            </p>
          </div>
          <div>
            <img className={classes.img} src="/assets/image/windowEcoline.jpg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default WindowEcoline;
