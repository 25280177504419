import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper/modules";
import st from "./swiper.module.scss";
import { motion } from "framer-motion";
import "swiper/scss";
import "swiper/scss/navigation";
import "swiper/scss/pagination";
import "./swiper.scss";
import { BigTitleSection } from "../../../components/ui/BigTitleSection";
import Wrapper from "../../../components/Wrapper";
import Container from "../../../components/Container";

interface SwiperInterfaceDate {
  subtitle: string;
  title: string;
  name: string;
  quantity: string;
  floors: string;
  yearOfCompletion: string;
  descriptions: string;
  address: string;
  image: string;
  image2: string;
}
interface DescriptionInterface {
  name: string;
}
export const dateForSwiper: SwiperInterfaceDate[] = [
  {
    subtitle: "Наши работы",
    title: "Жилой комплекс “Рассвет”",
    name: " Жилой комплекс “Рассвет”",
    quantity: " 4 блока ",
    floors: " 6 этажей ",
    yearOfCompletion: "Строительство было завершено в 2023 году.",
    descriptions:
      " Одной из особенностей ЖК “Рассвет” являются оконные системы и подоконники от Unopen. Эти высококачественные оконные конструкции обеспечивают отличную звуко и теплоизоляцию, а также защиту от нежелательных внешних воздействий. Их антрацитовый цвет придает эксклюзивный и современный вид дому.",
    address:
      "Комплекс расположен по адресу Фучика 25 (рядом с мясокомбинатом, а также остановкой конечного пункта маршрута 8).",
    image: "/assets/image/works1.jpg",
    image2: "/assets/image/works3.jpg",
  },
  {
    subtitle: "Наши работы",
    title: "Жилой комплекс борбад",
    name: " Жилой комплекс Борбад",
    quantity: " 2 блока",
    floors: " 16 этажей",
    yearOfCompletion: " Строительство было завершено в 2021 году.",
    descriptions:
      " Оконные системы Unopen обеспечивают надежную защиту от шума и сохраняют тепло внутри квартиры. А подоконники от Unopen, выполненные в прекрасном золотистом дубовом цвете, добавят неповторимый шарм интерьеру.",
    address:
      " Местоположение комплекса - Борбад 1/2, рядом с Коммерческим университетом и супермаркетом «Армут»",
    image: "/assets/image/works2.jpg",
    image2: "/assets/image/works4.jpg",
  },
];
const SwiperComponent = () => {
  const imgVariants = {
    hidden: {
      opacity: 0,
      x: 20,
      scale: 0.5,
    },
    animate: (i: number) => ({
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        delay: (i + 1) * 0.1,
      },
    }),
  };
  return (
    <Wrapper className={st.SwiperComponent}>
      <Container>
        <BigTitleSection>Портфолио</BigTitleSection>
      </Container>
      <div className={st.wrapper}>
        <div className={st.container}>
          <div className={st.swiper__main}>
            <Swiper
              className={st.swiper}
              modules={[Navigation, Scrollbar, A11y]}
              spaceBetween={50}
              slidesPerView={1}
              navigation
              scrollbar={{ draggable: true }}
            >
              {dateForSwiper.map((el) => (
                <SwiperSlide>
                  <div className={st.sl__wrapper}>
                    <motion.div
                      viewport={{
                        once: true,
                      }}
                      initial="hidden"
                      whileInView="animate"
                      variants={imgVariants}
                      className={st.sl__body}
                    >
                      <h3 className={st.sl__subtitle}>{el.subtitle}</h3>
                      <h2 className={"text_h2 " + st.title}>{el.title}</h2>
                      <p className={"text " + st.text}>
                        <span className={st.bold}>Название:</span>
                        {el.name}
                      </p>
                      <p className={"text " + st.text}>
                        <span className={st.bold}>Количество блоков:</span>
                        {el.quantity}
                      </p>
                      <p className={"text " + st.text}>
                        <span className={st.bold}>Количество этажей:</span>
                        {el.floors}
                      </p>
                      <p className={"text " + st.text}>
                        <span className={st.bold}>Год завершения: </span>
                        {el.yearOfCompletion}
                      </p>
                      <p className={"text " + st.text}>
                        <span className={st.bold}>Описание: </span>
                        {el.descriptions}
                      </p>
                      <p className={"text " + st.text}>
                        <span className={st.bold}> Адрес: </span>
                        {el.address}
                      </p>
                    </motion.div>
                    <Swiper
                      modules={[Pagination]}
                      spaceBetween={10}
                      slidesPerView={1}
                      pagination={{
                        clickableClass: `${st.vertical_Class}`,
                        enabled: true,
                        clickable: true,
                        bulletClass: `${st.bullet}`,
                        bulletActiveClass: `${st.bulletActive}`,
                      }}
                      nested={true}
                      className={st.swiper2}
                    >
                      <SwiperSlide>
                        <motion.div
                          viewport={{
                            once: true,
                          }}
                          initial="hidden"
                          whileInView="animate"
                          variants={imgVariants}
                          className={st.sl__img}
                        >
                          <img src={el.image} alt="sad"></img>
                        </motion.div>
                      </SwiperSlide>
                      <SwiperSlide>
                        <motion.div
                          viewport={{
                            once: true,
                          }}
                          initial="hidden"
                          whileInView="animate"
                          variants={imgVariants}
                          className={st.sl__img}
                        >
                          <img src={el.image2} alt="sad"></img>
                        </motion.div>
                      </SwiperSlide>
                    </Swiper>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default SwiperComponent;
