import { IBanner } from "../../models/IBanner"
import Banner from "../../components/Banner"
import { ITimeline } from "../../models/ITimeline"
import Timeline from "../../components/Timeline"
import Accordion from "../../components/Accordion"
import { IAccordion } from "../../models/IAccordion"
import BlockInfoFittings from "../../components/BlockInfoFittings"
import { IBlockInfoFittings } from "../../models/IBlockInfoFittings"

const bannerDate: IBanner = {
	title: 'ФУРНИТУРЫ',
	text: 'Окна ПВХ – пример гармоничного сочетания практичности,	высоких эстетических качеств и доступной стоимости.',
	image: '/assets/image/FittingsPage_banner.webp',
	link: 'Бесплатный замер'
}
const dateTimelineItems: ITimeline[] = [
	{
		key: 1,
		title: 'Поворотная фурнитура, с вертикальным подвесом',
		// subtitle: 'на рынке Таджикистана',
		text: 'Это наиболее распространенная разновидность.',
	}, {
		key: 2,
		title: 'Поворотно-откидная фурнитура ',
		text: 'Регулировка положения створки производится путем поворачивания ручки. В первом режиме поворота створка открывается обычным способом. Во втором – откидывается. В откинутом положении створка удерживается при помощи фрамуги, крепящейся к раме. Поворотно-откидной тип фурнитуры также применяется в системах проветривания.',
	}, {
		key: 3,
		title: 'Среднеподвесная фурнитура, с горизонтальным подвесом',
		text: 'Благодаря особенностям конструкции данного типа становится возможным открывание створки на 1800 (при этом ее можно зафиксировать в любом положении при помощи фрамужной системы на любой промежуток времени без риска провисания и деформации элементов конструкции). Среднеподвесную фурнитуру можно применять для окон необычной формы (например, для овальных или круглых).',
	}
]
const dateAccordion: IAccordion[] = [
	{
		name: 'Ограничитель поворота',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Защелка',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Откидные ножницы',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Детский замок',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Блокировщик поворота',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Многозапорный замок',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Оконная ручка',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Уплотнители',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Оконные петли',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Оконные цапфы и планки',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Угловой переключатель',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Оконные запоры',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Ролики',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	}, {
		name: 'Упоры',
		text: 'Данный элемент фурнитуры обеспечит хорошее проветривание помещения без риска внезапного раскрытия створок. Защелка фиксирует их положение в приоткрытом состоянии, при этом оставляя пространство, достаточное для прохождения потока свежего воздуха. Помимо пластиковых окон, данный элемент также может устанавливаться на балконную дверь. Защелки подразделяются на следующие типы:',
		items: [
			{
				itemsName: 'По размещению:',
				// itemsText: 'Врезные (размещают внутри конструкции оконной рамы)',
				items: [
					{
						// itemName: 'asd',
						itemText: 'Врезные (размещают внутри конструкции оконной рамы)',
					}, {
						// itemName: 'asd',
						itemText: 'Наружные (крепятся на оконной створке).',
					}
				]
			}, {
				itemsName: 'По наличию планки:',
				// itemsText: 'С запарной планкой, к которой прикрепляется зацеп.',
				items: [
					{
						// itemName: 'asd',
						itemText: 'С запарной планкой, к которой прикрепляется зацеп.',
					}, {
						// itemName: 'asd',
						itemText: 'Без запорной планки.',
					}
				]
			}
		]
	},
]
const dateBlockInfo: IBlockInfoFittings = {
	image: '/assets/image/img_321a.webp',
	title: 'Основные виды фурнитуры для пластиковых окон',
	text: 'Порой все положительные характеристики качественных пластиковых окон от ведущих производителей может свести на нет подбор неподходящей либо просто фурнитуры сомнительного качества. Со временем обнаружатся определенные дефекты, нарушающие нормальную эксплуатацию данной конструкции. Возможно, станет труднее открывать створки. Или начнет заедать поворотный механизм. Важно лишь одно: для обеспечения нормального функционирования всех элементов пластикового окна необходимо подобрать наиболее подходящую фурнитуру. В этой статье мы подробно рассмотрим все её виды, существующие в настоящее время.',
	linkButton: 'string'
}


const FittingsPage = () => {
	return (<>
		<Banner banner={bannerDate} />
		<BlockInfoFittings props={dateBlockInfo} buttonShow={false} />
		<Timeline
			imgDisplay={false}
			items={dateTimelineItems}
			subtitleMain='Основные преимущества'
			titleMain='ЧТО ДЕЛАЕТ ОКНО ROLLER	САМЫМ ЭФФЕКТИВНЫМ?'
		/>
		<Accordion title="ЭЛЕМЕНТЫ ФУРНИТУРЫ" props={dateAccordion} />
	</>)
}
export default FittingsPage