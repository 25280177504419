import React from "react";
import classes from "./solutionUnopen.module.scss";

function SolutionUnopen() {
  return (
    <div className={classes.solution}>
      <div className={classes.wrapper}>
        <div className={classes.width}>
          <h1 className={classes.h1}>Решения<br /> для вас!</h1>
        </div>
        <div className={classes.grid}>
          <div className={classes.flex}>
            <img className={classes.img} src="/assets/image/window-black.svg" alt="" />
            <div className={classes.paragraphs}>
              <div className={classes.near}>
                <p className={classes.paragraph1}>Количество камер:</p>
                <p className={classes.paragraph2}>5</p>
              </div>
              <div className={classes.near}>
                <p className={classes.paragraph1}>Монтажная ширина:</p>
                <p className={classes.paragraph2}>6500 мм</p>
              </div>
              <div className={classes.near}>
                <p className={classes.paragraph1}>Материал уплотнений:</p>
                <p className={classes.paragraph2}>АРТК, Силикон</p>
              </div>
            </div>
          </div>
          <div className={classes.flex}>
            <img
              className={classes.img}
              src="/assets/image/window-colorfull.svg"
              alt=""
            />
            <div className={classes.paragraphs}>
              <div className={classes.near}>
                <p className={classes.paragraph1}>Варианты цветовых решений:</p>
                <p className={classes.paragraph2}>Цветной и белый</p>
              </div>
              <div className={classes.near}>
                <p className={classes.paragraph1}>Система уплотнений:</p>
                <p className={classes.paragraph2}>2-х контурная</p>
              </div>
              <div className={classes.near}>
                <p className={classes.paragraph1}>Дверные системы:</p>
                <p className={classes.paragraph2}>Имеются</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SolutionUnopen;
