import { useEffect, useState } from "react"
import { IGalleryCategory } from "../../models/IPhotoGallery"
import PhotoGalleryWrapper from "./PhotoGalleryWrapper"
import item_1 from './item_1.jpg'

const PhotoGalleryPage = () => {
	const [categoriesGallery, setCategoriesGallery] = useState<IGalleryCategory[] | null >(null);

	useEffect(() => {
		fetch("https://api.roller.tj/category_gallery/")
		  .then((response) => response.json())
		  .then((categoriesGallery) => setCategoriesGallery(categoriesGallery));
	  }, []);

	  if (!categoriesGallery) {
		return <>loading...</>;
	  }
	
	return (<>
		<PhotoGalleryWrapper categoriesGallery={categoriesGallery} />
	</>)
}

export default PhotoGalleryPage