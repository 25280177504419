import { useInView } from "framer-motion";
import st from "./bunner.module.scss";
import { useEffect, useRef } from "react";
import clsx from "clsx";
import Wrapper from "../../../components/Wrapper";

const Banner = () => {
  const ref = useRef(null);
  const isView = useInView(ref, {
    once: false,
    amount: "some",
  });

  return (
    <Wrapper>
      <section className={clsx(st.banner, isView && st.view)} ref={ref}>
        <div className={st.banner__fon}>
          <div className={"_container " + st.container}>
            <h1 className={"text_h1 " + st.title}>Roller</h1>
            <p className={"text " + st.texting}>
              ВЕДУЩИЙ ПРОИЗВОДИТЕЛЬ ОКОННЫХ И ДВЕРНЫХ ПВХ-ПРОФИЛЕЙ В
              ТАДЖИКИСТАНЕ
            </p>
          </div>
        </div>
      </section>
    </Wrapper>
  );
};

export default Banner;
