import ColorsUnopen from "../colorsUnopen/ColorsUnopen";
import Advantages from "../advantages/Advantages";
import WindowTypes from "../windowTypes/WindowTypes";
import SolutionStella from "../solutionStella/SolutionStella";
import WindowStella from "../windowStella/WindowStella";
import DoorTypes from "../doorTypes/DoorTypes";
import { Link } from "react-router-dom";

function WrapperStella() {
  return (
   <div>
      <SolutionStella />
      <WindowStella />
      <WindowTypes />
      <DoorTypes/>
      <ColorsUnopen />
      <Advantages />
    </div>
  );
}

export default WrapperStella;
