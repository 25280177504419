import classes from "./advantages.module.scss";
import advantage_1 from "./img/advantage_1.svg";
import advantage_2 from "./img/advantage_2.svg";
import advantage_3 from "./img/advantage_3.svg";
import advantage_4 from "./img/advantage_4.svg";
import advantage_5 from "./img/advantage_5.svg";

function Advantages() {
  return (
    <div className={classes.advantages}>
      <div className={classes.wrapper}>
        <h1 className={classes.h1}>ПРЕИМУЩЕСТВА</h1>
        <div className={classes.grid}>
          <div className={classes.block1}>
            <p className={classes.paragraph}>Опыт</p>
            <div className={classes.group}>
              <p className={classes.paragraph2}>
                17 лет опыта
                <br />
                на рынке Таджикистана
              </p>
              <img className={classes.img} src={advantage_1} alt="" />
            </div>
          </div>
          <div className={classes.block2}>
            <p className={classes.paragraph}>Стандарты</p>
            <div className={classes.group}>
              <p className={classes.paragraph2}>
                Европейские стандарты исполнения
              </p>
              <img className={classes.img} src={advantage_4} alt="" />
            </div>
          </div>
          <div className={classes.block3}>
            <p className={classes.paragraph}>Ламинация</p>
            <div className={classes.group}>
              <p className={classes.paragraph2}>
                Высокое качество <br />
                ламинации от немецкой компании Renolit
              </p>
              <img className={classes.img} src={advantage_2} alt="" />
            </div>
          </div>
        </div>
        <div className={classes.grid}>
          <div className={classes.block4}>
            <p className={classes.paragraph}>Качество</p>
            <div className={classes.group}>
              <p className={classes.paragraph2}>
                Мы даем от 15 лет гарантии
                <br /> на наши системы ПФХ
              </p>
              <img className={classes.img} src={advantage_5} alt="" />
            </div>
          </div>
          <div className={classes.block5}>
            <p className={classes.paragraph}>Оборудование</p>
            <div className={classes.group}>
              <p className={classes.paragraph2}>
                Современное, немецкое и турецкое
                <br /> оборудование по международным стандартам
              </p>
              <img className={classes.img} src={advantage_3} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advantages;
