import st from "./AboutСompany.module.scss";
import Button from "../../../../components/ui/Button";
import { motion } from "framer-motion";

import { Link } from "react-router-dom";
import { BigTitleSection } from "../../../../components/ui/BigTitleSection";
import Container from "../../../../components/Container";
import Wrapper from "../../../../components/Wrapper";

const AboutСompany = () => {
  return (
    <motion.section
      initial={{
        opacity: 0,
        y: 40,
      }}
      exit={{
        opacity: 0,
        y: 40,
      }}
      transition={{
        duration: 0.5,
      }}
      viewport={{
        amount: "some",
        margin: "0px 0px -140px 0px",
        once: false,
      }}
      whileInView={{
        opacity: 1,
        y: 0,
      }}
      className={st.aboutСompany}
    >
      <Container className={st.container}>
        <div className={st.aboutСompany__wrapper}>
          <div className={st.aboutСompany__bl_text}>
            <div className={st.aboutСompany__text}>
              <div className={st.hidden}>
                {" "}
                <BigTitleSection>О&nbsp;компании</BigTitleSection>
              </div>
              <h1 className={st.h1mobi}>
                <span className={st.white}>КОМПАНИЯ</span><span className={st.red}>Roller</span>
              </h1>
              <div className={st.blockFlex}>
                <div className={st.aboutСompany__title}>
                  <h3 className={"text_h2 " + st.text_h2}>
                    Ведущий производитель оконных и дверных ПВХ-профилей в
                    таджикистане
                  </h3>
                  <h3 className={"text_h2 " + st.text_h2mobi}>
                    Ведущий производитель в области разработки и производства
                    оконных и дверных систем из высококачественного пластика
                  </h3>
                </div>
                <div
                  className={
                    st.aboutСompany__left + " " + st.aboutСompany__left__mobile
                  }
                >
                  <div>
                    <img
                      className={st.mobiImg}
                      src="/assets/image/main_img_2.webp"
                      alt="logo_company"
                    />
                  </div>
                </div>
              </div>
              <p className={"text " + st.subtitle}>
                КОМПАНИЯ ROLLER лидер в области разработки и производства
                оконных и дверных систем из высококачественного пластика. ROLLER
                был основан в 2006 и является первым производителем материалов
                из ПВХ в Таджикистане и сегодня наша компания является одним из
                лидеров по производству профилей для окон и дверей ПВХ,
                обшивочных пластик, канализационных труб, кабель-каналов в
                республике. Наша миссия - изменить жизнь людей к ...
              </p>
            </div>
          </div>
          <div className={st.aboutСompany__left}>
            <div className={st.aboutСompany__bl_img}>
              <img
                src="/assets/image/main_img_2.webp"
                width={368}
                height={399}
                alt="image"
              />
            </div>
          </div>
        </div>
        <div>
          <div>
            <Link to="aboutCompany">
              <Button children="Узнать больше" />
            </Link>
          </div>
        </div>
      </Container>
    </motion.section>
  );
};
export default AboutСompany;
