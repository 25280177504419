import React from "react";
import SolutionUnopen from "../solutionUnopen/SolutionUnopen";
import WindowUnopen from "../windowUnopen/WindowUnopen";
import ColorsUnopen from "../colorsUnopen/ColorsUnopen";
import Advantages from "../advantages/Advantages";
import WindowTypes from "../windowTypes/WindowTypes";
import DoorTypes from "../doorTypes/DoorTypes";

function WrapperUnopen() {
  return (
    <div>
      <SolutionUnopen />
      <WindowUnopen />
      <WindowTypes />
      <DoorTypes/>
      <ColorsUnopen />
      <Advantages />
    </div>
  );
}

export default WrapperUnopen;
