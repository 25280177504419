import { INewsItems } from '../../models/INewsItems'
import NewsContainer from './NewsContainer'
import { useState, useEffect } from 'react'

const NewsPage = () => {
  const [newDateItems, setNewDateItems] = useState<INewsItems[] | null>(null)

  useEffect(() => {
    fetch('https://api.roller.tj/news/')
      .then((response) => response.json())
      .then((data) => {
        const sortedNews = data.data.ns.sort((a: INewsItems, b: INewsItems) => {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        })
        setNewDateItems(sortedNews)
      })
      .catch((error) => console.error('Error fetching news:', error))
  }, [])

  if (!newDateItems) {
    return <>loading...</>
  }

  return (
    <>
      <NewsContainer items={newDateItems} />
    </>
  )
}
export default NewsPage
