import ColorsUnopen from "../colorsUnopen/ColorsUnopen";
import Advantages from "../advantages/Advantages";
import WindowTypes from "../windowTypes/WindowTypes";
import SolutionBestwin from "../solutionBestwin/SolutionBestwin";
import WindowBestwin from "../windowBestwin/WindowBestwin";
import DoorTypes from "../doorTypes/DoorTypes";
import { Link } from "react-router-dom";

function WrapperBestwin() {
  return (
    <div>
      <SolutionBestwin />
      <WindowBestwin />
      <WindowTypes />
      <DoorTypes/>
      <ColorsUnopen />
      <Advantages />
    </div>
  );
}

export default WrapperBestwin;
