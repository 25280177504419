import { useEffect, useState } from "react";
import FormMain from "../../components/FormMain";
import Contacts from "./Contacts";
import { IContact } from "../../models/IContacts";

const ContactsPage = () => {
  const [newContact, setNewContact] = useState<IContact[] | null>(null);

  useEffect(() => {
    fetch("https://api.roller.tj/contacts/")
      .then((response) => response.json())
      .then((newContact) => setNewContact(newContact));
  }, []);

  if (!newContact) {
    return <>loading...</>;
  }
  console.log('contact' , newContact);
  
  return (
    <>
      <Contacts newContact={newContact} />
      <FormMain
        titleChildren="свяжитесь с нами"
        textChildren="Отправьте онлайн форму для заказа и в течении дня Вам позвонят"
      />
    </>
  );
};
export default ContactsPage;
