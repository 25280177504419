import {
  Link,
  useNavigate,
  useNavigation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import Products from "../../components/Products";
import ProductsSwiper from "../../components/productsSwiper/ProductsSwiper";
import WrapperRoller from "../../components/wrapperRoller/WrapperRoller";
import { useState, useEffect } from "react";
import { IProduct } from "../../models/IProduct";
import WrapperUnopen from "../../components/wrapperUnopen/WrapperUnopen";
import WrapperStella from "../../components/wrapperStella/WrapperStella";
import WrapperEcoline from "../../components/wrapperEcoline/WrapperEcoline";
import WrapperBestwin from "../../components/wrapperBestwin/WrapperBestwin";

export const products: IProduct[] = [
  {
    id: 2,
    name: "ecoline",
    logo: "/assets/image/ecoline.svg",
    component: <WrapperEcoline />,
    link: "products/ecoline",
  },
  {
    id: 3,
    name: "unopen",
    logo: "/assets/image/unopen.svg",
    component: <WrapperUnopen />,
    link: "products/unopen",
  },
  {
    id: 1,
    name: "roller",
    logo: "/assets/image/roller.svg",
    component: <WrapperRoller />,
    link: "products/roller",
  },
  {
    id: 4,
    name: "stella",
    logo: "/assets/image/stella.svg",
    component: <WrapperStella />,
    link: "products/stella",
  },
  {
    id: 5,
    name: "bestwin",
    logo: "/assets/image/Bestwin.svg",
    component: <WrapperBestwin />,
    link: "products/bestwin",
  },
];

const ProductsPage = () => {
  const [searchParams] = useSearchParams();
  const [activeProductId, setActiveProductId] = useState(1);
  const activeProduct = products.find(({ id }) => id === activeProductId);

  useEffect(() => {
    setActiveProductId(Number(searchParams.get("id")) || 1);
  }, [searchParams]);

  return (
    <div>
      <ProductsSwiper
        selectProduct={setActiveProductId}
        activeProductId={activeProductId}
      />
      {activeProduct?.component}
    </div>
  );
};
export default ProductsPage;
