import st from "./partners.module.scss";
import { motion } from "framer-motion";

import PartnerItem from "./PartnerItem";
import { BigTitleSection } from "../../../components/ui/BigTitleSection";
import Wrapper from "../../../components/Wrapper";
interface PartnersInterface {
  title: string;
  sistemy: string;
  country: string;
  standart: string;
  image: string;
  date: PartnerInterface[];
}
interface PartnerInterface {
  image: string;
  title: string;
  text: string;
  certification: string;
  number: string;
}

const PartnersDate: PartnersInterface[] = [
  {
    image: "/assets/image/partnerLogo_0.webp",
    title: "Ламинация",
    sistemy: "Высококачественные полимерные пленки",
    country: "Германия",
    standart: "ISO 9001",
    date: [
      {
        image: "/assets/image/Renolit.jpg",
        title: "Renolit",
        text: "Был проведен аудит, отчет № 70008547. Было представлено доказательство того, что требования в соответствии с ISO 50001: 2011 выполняются.",
        certification: "Регистрационный номер сертификата: ",
        number: "12 340 20431 TMS.",
      },
    ],
  },
  {
    image: "/assets/image/partnerLogo_1.svg",
    title: "Фурнитуры",
    sistemy: "Комплектующие системы",
    country: "Турция",
    standart: "ISO 9001",
    date: [
      {
        image: "/assets/image/fornax.jpg",
        title: "Fornax",
        text: "Компания с указанным выше названием является нашим дилером и продает фирменные дверные и оконные аксессуары Fornax. Авторизован",
        certification: "",
        number: '',
      },
    ],
  },
  {
    // image: '/assets/image/partnerLogo_2.svg',
    image: "/assets/image/partnerLogo_3.svg",
    title: "Фурнитуры",
    sistemy: "Комплектующие системы",
    country: "Турция",
    standart: "ISO 9001",
    date: [
      {
        image: "/assets/image/certificate_1.webp",
        title: "Winax",
        text: "Комплектующие системы Страна:Турция Стандарт:ISO 9001",
        certification: "",
        number: '',
      },
    ],
  },
  {
    image: "/assets/image/partnerLogo_8.webp",
    title: "ПВХ",
    sistemy: "Гранулированный ПВХ",
    country: "Турция",
    standart: "ISO 9001",
    date: [
      {
        image: "/assets/image/devaPlastik.jpg",
        title: "Deva Plastik",
        text: "Настоящим подтверждается, что вышеупомянутая Компания соответствует требованиям Системы экологического менеджмента.",
        certification: "Номера сертификации:",
        number: " MTS-13216 ",
      },
      {
        image: "/assets/image/devaPlastik2.jpg",
        title: "Deva Plastik",
        text: "Настоящим подтверждается, что вышеупомянутая Компания соответствует требованиям Системы экологического менеджмента.",
        certification: "Номера сертификации:",
        number: "MTS-13215",
      },
      {
        image: "assets/image/devaPlastik3.jpg",
        title: "Deva Plastik",
        text: "Настоящим подтверждается, что вышеупомянутая Компания соответствует требованиям Системы экологического менеджмента.",
        certification: "Номера сертификации:",
        number: "MTS-13218",
      },
      {
        image: "assets/image/devaPlastik4.jpg",
        title: "Deva Plastik",
        text: "Настоящим подтверждается, что вышеупомянутая Компания соответствует требованиям Системы экологического менеджмента.",
        certification: "Номера сертификации:",
        number: "MTS-13217",
      },
    ],
  },
  {
    image: "/assets/image/partnerLogo_4.svg",
    title: "Производство",
    sistemy: "Экструзионная линия для производства ПВХ панелей",
    country: "Голландия, Турция",
    standart: "ISO 9001",
    date: [
      {
        image: "/assets/image/mikrosan.webp",
        title: "Mikrosan",
        text: "Экструзионная линия для производства ПВХ панелей Страна:Голландия, Турция Стандарт:ISO 9001",
        certification: "",
        number: '',
      },
    ],
  },
  {
    image: "/assets/image/partnerLogo_2.svg",
    title: "Фурнитуры",
    sistemy: "Комплектующие системы",
    country: "Голландия, Турция",
    standart: "ISO 9001",
    date: [
      {
        image: "/assets/image/fores.jpg",
        title: "Fores",
        text: "Наличие этого документа дает полное право продавать и оказывать техническую поддержку оконной и дверной фурнитуры марки FORES.",
        certification: "",
        number: '',
      },
    ],
  },
];

const Partners = () => {
  const imgVariants = {
    hidden: {
      opacity: 0,
      x: 20,
      scale: 0.5,
    },
    animate: (i: number) => ({
      opacity: 1,
      scale: 1,
      x: 0,
      transition: {
        delay: (i + 1) * 0.1,
      },
    }),
  };

  return (
    <Wrapper className={st.partners}>
      <div className={"_container " + st.container}>
        <div className={st.partners__title_body}>
          {/* <h2 className={'text_h2 ' + st.partners__title} >наши партнёры</h2> */}
          <BigTitleSection>партнёры</BigTitleSection>
          <div className={st.partners__text_block}>
            <p className={st.partners__text}>
              Мы предоставляем высокое качество работ и точные сроки исполнения
              заказа. Используем в производстве проверенные немецкие технологии
              и оборудование. Высококачественное сырье завозим из Германии,
              Турции, России, Казахстан и Китая
            </p>
          </div>
        </div>
        <ul className={st.partners__items}>
          {PartnersDate.map((el, index) => (
            <motion.li
              initial="hidden"
              viewport={{
                once: true,
              }}
              whileInView="animate"
              custom={index}
              variants={imgVariants}
              className={st.partner__item}
            >
              <PartnerItem classname={st.partner} partnerItem={el} />
            </motion.li>
          ))}
          <div className={st.fon}>
            <img src="/assets/image/partners_fon.svg" />
          </div>
        </ul>
      </div>
    </Wrapper>
  );
};

export default Partners;
